import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Error404() {
  const navigate = useNavigate();
  

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <h1>404</h1>
      <h2>Sayfa Bulunamadi</h2>
      <Button onClick={() => navigate("/")} variant="contained">
        Anasayfaya Don
      </Button>
    </div>
  );
}

export default Error404;
