import React from "react";
import {
  BrowserRouter as Router,
  Route as DomRoute,
  Routes,
} from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AuthContext from "./authContext/AuthContext";
import AuthProvider from "./authContext/AuthProvider";

import ProtectedRoute from "./components/ProtectedRoute";

import Home from "./pages/Home";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Admin from "./pages/Admin";
import Error404 from "./pages/Error404";

export default function App() {
  // connect mongo 27017
  const { auth, changeAuth } = React.useContext(AuthContext);
  console.log("Auth:", auth);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <AuthProvider>
        <Router>
          <div>
            <div id="content">
              <Routes>
                <DomRoute
                  path="/"
                  element={
                    <ProtectedRoute
                      noAccess={<Login />}
                      accessRoles={["admin", "usr"]}
                    >
                      <Home />
                    </ProtectedRoute>
                  }
                />
                <DomRoute path="/login" element={<Login />} />
                <DomRoute path="/signup" element={<Signup />} />
                <DomRoute
                  path="/admin"
                  element={
                    <ProtectedRoute
                      noAccess={<Login />}
                      accessRoles={["admin"]}
                    >
                      <Admin />
                    </ProtectedRoute>
                  }
                />
                <DomRoute path="*" element={<Error404 />} />
              </Routes>
            </div>
          </div>
        </Router>
      </AuthProvider>
    </LocalizationProvider>
  );
}
