import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import { Card, CardContent, Typography } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import img from "../../media/des.jpg";

function Admin() {
  const members = [
    {
      name: "John",
      surname: "Doe",
      phone: "123456789",
      email: "jhon@gmail.com",
      packages: [
        { name: "Kano", remaining_usage: 3, expiration_date: "30Tem" },
        { name: "Yoga", remaining_usage: 4, expiration_date: "30Tem" },
      ],
    },
    {
      name: "John",
      surname: "Doe",
      phone: "123456789",
      email: "jhon@gmail.com",
      packages: [
        { name: "Kano", remaining_usage: 5, expiration_date: "30Tem" },
        { name: "Yoga", remaining_usage: 6, expiration_date: "30Tem" },
      ],
    },
    {
      name: "John",
      surname: "Doe",
      phone: "123456789",
      email: "jhon@gmail.com",
      packages: [
        { name: "Kano", remaining_usage: 7, expiration_date: "30Tem" },
        { name: "Yoga", remaining_usage: 8, expiration_date: "30Tem" },
      ],
    },
    {
      name: "John",
      surname: "Doe",
      phone: "123456789",
      email: "jhon@gmail.com",
      packages: [
        { name: "Kano", remaining_usage: 3, expiration_date: "30Tem" },
        { name: "Yoga", remaining_usage: 4, expiration_date: "30Tem" },
      ],
    },
    {
      name: "John",
      surname: "Doe",
      phone: "123456789",
      email: "jhon@gmail.com",
      packages: [
        { name: "Kano", remaining_usage: 7, expiration_date: "30Tem" },
        { name: "Yoga", remaining_usage: 8, expiration_date: "30Tem" },
      ],
    },
    {
      name: "John",
      surname: "Doe",
      phone: "123456789",
      email: "jhon@gmail.com",
      packages: [
        { name: "Kano", remaining_usage: 3, expiration_date: "30Tem" },
        { name: "Yoga", remaining_usage: 4, expiration_date: "30Tem" },
      ],
    },
    {
      name: "John",
      surname: "Doe",
      phone: "123456789",
      email: "jhon@gmail.com",
      packages: [
        { name: "Kano", remaining_usage: 3, expiration_date: "30Tem" },
        { name: "Yoga", remaining_usage: 4, expiration_date: "30Tem" },
      ],
    },
    {
      name: "John",
      surname: "Doe",
      phone: "123456789",
      email: "jhon@gmail.com",
      packages: [
        { name: "Kano", remaining_usage: 5, expiration_date: "30Tem" },
        { name: "Yoga", remaining_usage: 6, expiration_date: "30Tem" },
      ],
    },
    {
      name: "John",
      surname: "Doe",
      phone: "123456789",
      email: "jhon@gmail.com",
      packages: [
        { name: "Kano", remaining_usage: 7, expiration_date: "30Tem" },
        { name: "Yoga", remaining_usage: 8, expiration_date: "30Tem" },
      ],
    },
    {
      name: "John",
      surname: "Doe",
      phone: "123456789",
      email: "jhon@gmail.com",
      packages: [
        { name: "Kano", remaining_usage: 3, expiration_date: "30Tem" },
        { name: "Yoga", remaining_usage: 4, expiration_date: "30Tem" },
      ],
    },
  ];

  const [eventData, setEventData] = useState({
    date: "",
    timeStart: "",
    name: "",
    description: "",
  });

  const handleInputChangeForEvent = (e) => {
    setEventData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmitForEvent = (e) => {
    e.preventDefault();
    // Do something with the form data
  };

  const [planData, setPlanData] = useState({
    dateTimeStart: "",
    dateTimeEnd: "",
    name: "",
    description: "",
    price: "",
    usageCount: "",
  });

  const handleInputChangeForPlan = (e) => {
    setPlanData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmitForPlan = (e) => {
    e.preventDefault();
    // Do something with the form data
  };

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundImage: `url(${img})`,
        // backgroundSize: "cover",
        backgroundRepeat: "repeat",
        backgroundPosition: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            borderRadius: "5px",
            paddingRight: "10px",
            paddingLeft: "10px",
            color: "white",
          }}
        >
          Admin Panel
        </h1>
        <h2
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            borderRadius: "5px",
            paddingRight: "10px",
            paddingLeft: "10px",
            color: "white",
          }}
        >
          Etkinlik Oluştur
        </h2>
        <DatePicker
          label="Etkinlik Tarihi"
          sx={{
            bgcolor: "rgba(255, 255, 255, 0.8)",
            borderRadius: "5px",
            marginBottom: "10px",
            boxShadow: "0px -7px 10px 0px rgba(255,255,255,0.75)",
          }}
        />
        <TimePicker
          sx={{
            bgcolor: "rgba(255, 255, 255, 0.8)",
            borderRadius: "5px",
            marginBottom: "10px",
            boxShadow: "0px -7px 10px 0px rgba(255,255,255,0.75)",
          }}
          label="Etkinlik Saati"
        />
        <TextField
          style={{
            borderRadius: "5px",
            marginBottom: "10px",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
          name="name"
          label="İsim"
          value={eventData.name}
          onChange={handleInputChangeForEvent}
          required
        />
        <TextField
          style={{
            borderRadius: "5px",
            marginBottom: "10px",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
          name="description"
          label="Açıklama"
          value={eventData.description}
          onChange={handleInputChangeForEvent}
          required
        />
        <TextField
          style={{
            borderRadius: "5px",
            marginBottom: "10px",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
          name="quota"
          label="Kota"
          value={eventData.quota}
          onChange={handleInputChangeForEvent}
          required
        />
        <Button
          onClick={handleSubmitForEvent}
          variant="contained"
          color="primary"
          style={{ backgroundColor: "#2196F3" }}
        >
          Oluştur
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h2
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            borderRadius: "5px",
            paddingRight: "10px",
            paddingLeft: "10px",
            color: "white",
          }}
        >
          Üyelik Planı Oluştur
        </h2>
        <DatePicker
          label="Başlangıç Tarihi"
          sx={{
            bgcolor: "rgba(255, 255, 255, 0.8)",
            borderRadius: "5px",
            marginBottom: "10px",
            boxShadow: "0px -7px 10px 0px rgba(255,255,255,0.75)",
          }}
        />
        <DatePicker
          label="Bitiş Tarihi"
          sx={{
            bgcolor: "rgba(255, 255, 255, 0.8)",
            borderRadius: "5px",
            marginBottom: "10px",
            boxShadow: "0px -7px 10px 0px rgba(255,255,255,0.75)",
          }}
        />
        <TextField
          style={{
            borderRadius: "5px",
            marginBottom: "10px",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
          name="name"
          label="İsim"
          value={planData.name}
          onChange={handleInputChangeForPlan}
          required
        />
        <TextField
          style={{
            borderRadius: "5px",
            marginBottom: "10px",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
          name="description"
          label="Açıklama"
          value={planData.description}
          onChange={handleInputChangeForPlan}
          required
        />
        <TextField
          style={{
            borderRadius: "5px",
            marginBottom: "10px",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
          name="price"
          label="Fiyat"
          value={planData.price}
          onChange={handleInputChangeForPlan}
          required
        />
        <TextField
          style={{
            borderRadius: "5px",
            marginBottom: "10px",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
          name="usageCount"
          label="Kullanım Sayısı"
          value={planData.usageCount}
          onChange={handleInputChangeForPlan}
          required
        />

        <Button
          onClick={handleSubmitForPlan}
          variant="contained"
          color="primary"
          style={{ backgroundColor: "#2196F3" }}
        >
          Oluştur
        </Button>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            borderRadius: "5px",
            paddingRight: "10px",
            paddingLeft: "10px",
            color: "white",
          }}
        >
          249 Üye
        </h1>
        {members.map((_p) => (
          <Card key={_p.name} style={{ marginBottom: "20px", width: "400px", position: "relative" }}>
            <CardContent>
              <Typography variant="h5" component="h2">
                {_p.name} {_p.surname}
              </Typography>
              <Button
                onClick={() => {}}
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "#2196F3",
                  position: "absolute",
                  right: "10px",
                  top: "10px",
                }}
              >
                Plan Tanımla
              </Button>
              <Typography cgutterBottom>{_p.phone}</Typography>
              <Typography gutterBottom>{_p.email}</Typography>
              <hr style={{ opacity: 0.2 }}></hr>
              {_p.packages.map((_package) => (
                <div>
                  <Typography variant="body2" component="p">
                    {_package.name}
                  </Typography>
                  <Typography color="#777777">
                    Kalan: {_package.remaining_usage} Bitiş:{" "}
                    {_package.expiration_date}
                  </Typography>
                </div>
              ))}
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
}

export default Admin;
