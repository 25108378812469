import React, { useContext } from "react";

import AuthContext from "../../authContext/AuthContext";

function ProtectedRoute({ children, noAccess, accessRoles }) {
  const { auth } = useContext(AuthContext);
  // if user is not authenticated
  if (accessRoles && accessRoles.includes(auth)) {
    return children;
  }
  return noAccess;
}

export default ProtectedRoute;
