import React, { createContext, useContext, useState, FC } from "react";
import AuthContext from "./AuthContext";

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState("guest");

  const changeAuth = (value) => {
    setAuth(value.auth);
  };
  return (
    <AuthContext.Provider value={{ auth, changeAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
export default AuthProvider;
