import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import logo from "../../media/logo.jpg";

import img from "../../media/e.jpg";

function Signup() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [surname, setSurname] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleSurnameChange = (e) => {
    setSurname(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleSignup = () => {
    // Perform login logic here
    console.log("Email:", email);
    console.log("Password:", password);
    navigate("/login");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flex: 1,
        height: "100vh",
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
       <img
        src={logo}
        alt="logo"
        style={{
          width: "5rem",
          height: "5rem",
          objectFit: "cover",
          borderRadius: "50%",
          marginTop: "1rem",
        }}
      />
      <h1
        style={{
          color: "white",
        }}
      >
        Kayıt Ol
      </h1>
      <TextField
        label="İsim"
        value={name}
        onChange={handleNameChange}
        style={{
          borderRadius: "5px",
          marginBottom: "10px",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
        }}
      />

      <TextField
        label="Soyisim"
        value={surname}
        onChange={handleSurnameChange}
        style={{
          borderRadius: "5px",
          marginBottom: "10px",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
        }}
      />

      <TextField
        label="Telefon"
        value={phone}
        onChange={handlePhoneChange}
        style={{
          borderRadius: "5px",
          marginBottom: "10px",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
        }}
      />

      <TextField
        label="Email"
        value={email}
        onChange={handleEmailChange}
        style={{
          borderRadius: "5px",
          marginBottom: "10px",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
        }}
      />
      <TextField
        label="Şifre Belirle"
        type="password"
        value={password}
        onChange={handlePasswordChange}
        style={{
          borderRadius: "5px",
          marginBottom: "10px",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
        }}
      />
      <Button variant="contained" color="primary" onClick={handleSignup}>
        Kayıt Ol
      </Button>
    </div>
  );
}

export default Signup;
