import React, { useState } from "react";
import { Button } from "@mui/material";
import { Card, CardContent, Typography } from "@mui/material";
import AuthContext from "../../authContext/AuthContext";
import { useNavigate } from "react-router-dom";
import img from "../../media/h.jpg";
import logo from "../../media/logo.jpg";

function Home() {
  const navigate = useNavigate();
  const { auth, changeAuth } = React.useContext(AuthContext);
  const packages = [
    {
      name: "Kano",
      remaining_usage: 10,
      description:
        "Temel kano pakeeti 10 kullanim hakki, 1 saatlik egitim. 1Tem-30Tem arasi gecerlidir",
      expiration_date: "30Tem",
    },
    {
      name: "Sup Yoga",
      remaining_usage: 8,
      description:
        "Haftada iki gun sabah 8-9 arasi Sup yoga dersi. 1Tem-30Tem arasi gecerlidir",
      expiration_date: "30Tem",
    },
  ];

  const events = [
    {
      name: "Kano",
      date: "27Tem 18:00",
      description: "is cikisi harika bir kano turu",
      remaining_quota: 10,
    },
    {
      name: "Sup Yoga",
      date: "25Tem 8:00",
      description: "Sabah 8-9 arasi Sup yoga dersi. ",
      remaining_quota: 3,
    },
    {
      name: "Kano",
      date: "27Tem 18:00",
      description: "is cikisi harika bir kano turu",
      remaining_quota: 10,
    },
    {
      name: "Sup Yoga",
      date: "25Tem 8:00",
      description: "Sabah 8-9 arasi Sup yoga dersi. ",
      remaining_quota: 3,
    },
  ];

  const navigateToAdminPanel = () => {
    navigate("/admin");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        overflowY: "hidden",
      }}
    >
      <div
        style={{
          flex: 1,
          overflowY: "auto",
          padding: "20px 0",
          scrollbarWidth: "none",
        }}
      >
        <img
          src={logo}
          alt="logo"
          style={{
            margin: "0 0 0 40%",
            width: "5rem",
            height: "5rem",
            objectFit: "cover",
            borderRadius: "50%",
            boxShadow: "0px 0px 50px #80DEEA",
          }}
        />
        <h1
          style={{
            color: "white",
            textAlign: "center",
            fontSize: "3em",
          }}
        >
          Hoşgeldiniz
        </h1>
        {packages.map((_p) => (
          <Card
            key={_p.name}
            style={{
              marginBottom: "20px",
              width: "400px",
              backgroundColor: "rgba(255, 255, 255, 0.9)",
            }}
          >
            <CardContent>
              <Typography variant="h5" component="h2">
                {_p.name}
              </Typography>
              <div style={{ flexDirection: "row", flexWrap: "wrap" }}>
                <Typography
                  display="inline"
                  style={{ marginRight: "5px" }}
                  gutterBottom
                >
                  Kalan Kullanim:
                </Typography>
                <Typography display="inline" color="#4BB543" gutterBottom>
                  {_p.remaining_usage}
                </Typography>
              </div>
              <div style={{ flexDirection: "row", flexWrap: "wrap" }}>
                <Typography
                  display="inline"
                  style={{ marginRight: "5px" }}
                  variant="body2"
                  component="p"
                >
                  Son Kullanim Tarihi:
                </Typography>
                <Typography
                  display="inline"
                  color="#FF0000"
                  variant="body2"
                  component="p"
                >
                  {_p.expiration_date}
                </Typography>
              </div>
              <Typography variant="body2" component="p">
                {_p.description}
              </Typography>
            </CardContent>
          </Card>
        ))}

        <h1
          style={{
            color: "white",
          }}
        >
          Etkinlikler
        </h1>
        {events.map((_e) => (
          <Card
            key={_e.name}
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.9)",
              marginBottom: "20px",
              width: "400px",
            }}
          >
            <CardContent>
              <Typography variant="h5" component="h2">
                {_e.name}
              </Typography>
              <Typography color="textSecondary" gutterBottom>
                Tarih: {_e.date}
              </Typography>
              <Typography variant="body2" component="p">
                {_e.description}
              </Typography>
              <Typography variant="body2" component="p">
                Kalan Kontenjan: {_e.remaining_quota}
              </Typography>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#2196F3" }}
                >
                  KATIL
                </Button>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
      {auth === "admin" ? (
        <Button
          variant="contained"
          style={{ backgroundColor: "#000" }}
          onClick={navigateToAdminPanel}
        >
          Admin Panel
        </Button>
      ) : null}
    </div>
  );
}

export default Home;
