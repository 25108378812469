import React, { useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../authContext/AuthContext";
import img from "../../media/i.jpg";
import logo from "../../media/logo.jpg";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const { auth, changeAuth } = React.useContext(AuthContext);
  const handleLogin = () => {
    // Perform login logic here
    console.log("Email:", email);
    console.log("Password:", password);
    if (email === "admin@gmail.com" && password === "admin1212") {
      changeAuth({ auth: "admin" });
      navigate("/");
    } else if (email === "usr@gmail.com" && password === "usr1212") {
      changeAuth({ auth: "usr" });
      navigate("/");
    }
  };
  console.log("auth;", auth);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flex: 1,
        height: "100vh",
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <img
        src={logo}
        alt="logo"
        style={{
          width: "5rem",
          height: "5rem",
          objectFit: "cover",
          borderRadius: "50%",
          marginTop: "1rem",
          boxShadow: "0px 0px 50px #E3F2FD",
        }}
      />
      <h1
        style={{
          color: "white",
        }}
      >
        Giriş
      </h1>
      <TextField
        label="Email"
        value={email}
        onChange={handleEmailChange}
        style={{
          borderRadius: "5px",
          marginBottom: "10px",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
        }}
      />
      <TextField
        label="Şifre"
        type="password"
        value={password}
        onChange={handlePasswordChange}
        style={{ marginBottom: "10px" }}
        style={{
          borderRadius: "5px",
          marginBottom: "10px",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
        }}
      />
      <Typography
        style={{
          cursor: "pointer",
          marginBottom: "10px",
          color: "white",
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          paddingRight: "10px",
          paddingLeft: "10px",
          borderRadius: "5px",
        }}
        onClick={() => {
          navigate("/signup");
        }}
      >
        Hesabım yok. Kayıt Ol
      </Typography>
      <Button variant="contained" color="primary" onClick={handleLogin}>
        GİRİŞ
      </Button>
    </div>
  );
}

export default Login;
